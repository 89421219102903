import Image from 'next/image';
import PropTypes from 'prop-types';

function Loader({
    type, height, width, className, message = '', opacity = 1
}) {
    return (<>
        {type === 'type1' && (
            <div className={'bg-basic-200 fixed inset-0  flex flex-col gap-2 justify-center items-center z-20 bg-opacity-[var(--loader-opacity)]'}
                style={{
                    '--loader-opacity': opacity
                }}
            >
                <span className='text-gray-100'>{message}</span>
                <Image src="/images/loader.svg" alt='loading' width="32" height="32" className="animate-spin" />
            </div>
        )}

        {type === 'type2' && (
            <div className={`bg-basic-200 flex justify-center items-center z-10 ${height} ${width} ${className}`}>
                <Image src="/images/deonLoader.gif" alt='deon' width="150" height="150" />
            </div>
        )}
        {type === 'type3' && (
            <div className='bg-basic-200 fixed inset-0  flex flex-col gap-2 justify-center items-center z-20 bg-opacity-[var(--loader-opacity)]'
                style={{
                    '--loader-opacity': opacity
                }}
            >
                <div className={` flex justify-center items-center z-10 ${height} ${width} ${className}`}>
                    <Image src="/images/deonLoader.gif" alt='deon' width="150" height="150" />
                </div>
            </div>
        )}
    </>
    );
}

export default Loader;

Loader.defaultProps = {
    type: 'type1'
};
Loader.propTypes = {
    type: PropTypes.oneOf(['type1', 'type2', 'type3'])

};
