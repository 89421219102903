import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
    getDoc,
    getFirestore, setDoc, updateDoc
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
};

let firebaseApp;
try {
    firebaseApp = initializeApp(firebaseConfig);
} catch (e) {
    firebaseApp = getApps()[0];
}
export const app = firebaseApp;
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
export const setDocs = async (...args) => {
    try { setDoc(...args); } catch (error) { console.error(error); }
};
export const updateDocs = async (...args) => {
    try { updateDoc(...args); } catch (error) { console.error(error); }
};
export const getDocs = async (...args) => {
    try { getDoc(...args); } catch (error) { console.error(error); }
};
