import { addDays } from 'date-fns';
import Router from 'next/router';
import { Cookies } from 'react-cookie';
import { socket } from 'services/socket';

const cookies = new Cookies();

const getAuth = () => cookies.get('AUTH');

const setAuth = (value) => cookies.set('AUTH', value, { path: '/' });

const removeAuth = () => cookies.remove('AUTH', { path: '/' });

const isAuthenticated = () => {
    const auth = getAuth();
    return auth != null && auth.token;
};

const logout = () => {
    removeAuth();
    if (socket.connected) socket.disconnect();
    Router.push('/');
};

export const getRefreshToken = () => {
    const auth = cookies.get('RT');
    return auth;
};

export const removeRT = () => {
    cookies.remove('RT', { path: '/' });
};

export const setRefreshToken = (refreshToken) => {
    cookies.set('RT', refreshToken, { path: '/' });
    return refreshToken;
};

export {
    getAuth, isAuthenticated, logout, removeAuth, setAuth
};
