import Link from 'next/link';
import PropTypes from 'prop-types';
import Text from './Text';

const MenuList = ({ menuItem = [], listClass, ...properties }) => (
    <nav>
        <ul className={`flex ${listClass}`}>
            {menuItem.map((menu, index) => (
                <Link href={menu.link} key={menu.title}>
                    <li key={index} className='flex-none'>
                        <Text variant='bodySmall'
                            className={`cursor-pointer hover:text-primary-300 transition-all px-3 ease-in-out duration-700 ${menu.isActive ? 'text-primary-300' : 'text-gray-50'} ${properties.className}`} >
                            {menu.title}
                        </Text>
                    </li>
                </Link>
            ))}
        </ul>
    </nav>
);

export default MenuList;

MenuList.propTypes = {
    className: PropTypes.string,
    listClass: PropTypes.string,
    menuItem: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string,
            isActive: PropTypes.bool
        })
    )
};
