import { socket, socketConnect } from 'services/socket';

const { loaderDisabledPages } = require('lib/constants');
const { useState, useEffect } = require('react');
const { default: Loader } = require('./Loader');

const AppLoader = ({ path }) => {
    const [socketConnected, setSocketConnected] = useState(false);
    const onSocketConnected = () => {
        setSocketConnected(true);
    };
    const onSocketDisconnected = () => {
        setSocketConnected(false);
    };
    useEffect(() => {
        socketConnect();
        socket.on('connect', onSocketConnected);
        socket.on('disconnect', onSocketDisconnected);
        return () => {
            socket.off('connect', onSocketConnected);
            socket.off('disconnect', onSocketDisconnected);
        };
    }, []);
    return <>{!loaderDisabledPages.includes(path)
        && !socketConnected && <Loader type='type3' opacity={0.65} />
    }</>;
};
export default AppLoader;
