import mixpanel from 'mixpanel-browser';

const init = () => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
        debug: true,
    })
}
init()

const trackEvent = (eventName = '', dataObj = {}) => {
    mixpanel.track(eventName, dataObj);
};

module.exports = {
    trackEvent
}