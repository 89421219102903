import PropTypes from 'prop-types';

const Text = ({
    variant,
    fontSize,
    fontWeight,
    children,
    className,
    onClick,
    ...props
}) => {
    const TextStyle = {
        body: 'text-base leading-4',
        bodySmall: 'text-sm leading-5',
        caption: 'text-xs leading-4',
        small: 'text-[10px] leading-3'
    };
    return (
        <p
            className={`${TextStyle[variant] || TextStyle.body} ${fontSize} ${fontWeight || ''} ${className || ''}`}
            onClick={onClick} {...props}
        >
            {children}
        </p>
    );
};

export default Text;

Text.propTypes = {
    variant: PropTypes.oneOf(['body', 'bodySmall', 'caption']),
    children: PropTypes.any,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.oneOf([
        'font-thin',
        'font-hairline',
        'font-extralight',
        'font-light',
        'font-normal',
        'font-medium',
        'font-semibold',
        'font-bold',
        'font-extrabold',
        'font-black'
    ]),
    className: PropTypes.string,
    onClick: PropTypes.func
};

Text.defaultProps = {
    variant: 'body',
    fontSize: '',
    fontWeight: 'font-normal',
    children: '',
    className: '',
    onClick: () => {}
};
