import WithAuth from '@/components/Auth/WithAuth';
import { StatusProvider } from '@/components/context/StatusContext';
import 'regenerator-runtime/runtime';

import AppLoader from 'atoms/AppLoader';
import axios from 'axios';
import { signOut } from 'firebase/auth';
import {
    doc,
    onSnapshot
} from 'firebase/firestore';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';
import { ErrorBoundary } from 'lib/bugsnag';
import { complianceRoles } from 'lib/constants';
import getPageTitle from 'lib/getPageTitle';
import LogRocket from 'logrocket';
import Head from 'next/head';
import Header from 'organisms/Header';
import { hasPath } from 'ramda';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getAuth,
    getRefreshToken,
    isAuthenticated,
    logout,
    removeAuth, removeRT, setAuth
} from 'services/auth.service';
import {
    db,
    auth as firebaseAuth,
    setDocs, updateDocs
} from 'services/firebase.service';
import { socket, socketConnect, socketEmit } from 'services/socket';
import '../styles/globals.css';

function isValidJSONString(str) { try { if (!str) return false; JSON.parse(str); return true; } catch (error) { return false; } }

let called = false;
async function renewToken(refreshToken, id) {
    called = true;
    return axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/refresh`, { refreshToken, id });
}

axios.interceptors.response.use((response) => response, async (error) => {
    const originalRequest = error.config;
    if (originalRequest.url.includes('/auth/refresh')) {
        LogRocket.log('logout', error);

        removeAuth();
        removeRT();
        if (typeof window !== 'undefined') {
            window.open('/', '_self');
        }
        return Promise.reject(error);
    }
    if (error.response?.status === 401) {
        try {
            if (called) return;
            const auth = getAuth();
            const refreshToken = getRefreshToken();
            const response = await renewToken(refreshToken, auth.id);
            called = false;
            setAuth({ ...auth, token: response.data.entity.token });
            if (typeof window !== 'undefined') {
                window.location.reload();
            }
        } catch (error) {
            called = false;
            LogRocket.log('logout', error);

            removeAuth();
            removeRT();
            if (typeof window !== 'undefined') {
                window.open('/', '_self');
            }
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
});

function MyApp({
    Component, pageProps, flagsmithState, router
}) {
    try {
        if (process.env.NEXT_PUBLIC_LOGROCKET_APP_ID) {
            LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID, {
                network: {
                    requestSanitizer: (request) => {
                        if (request.headers['x-access-token']) request.headers['x-access-token'] = '**redacted**';
                        if (isValidJSONString(request.body) && JSON.parse(request.body).password) {
                            const body = JSON.parse(request.body);
                            body.password = '**redacted**';
                            request.body = JSON.stringify(body);
                        }
                        return request;
                    },
                    responseSanitizer: (response) => {
                        if (isValidJSONString(response.body) && hasPath(['entity', 'token'], JSON.parse(response.body))) {
                            const body = JSON.parse(response.body);
                            body.entity.token = '**redacted**';
                            body.entity.fbToken = '**redacted**';
                            body.entity.refreshToken = '**redacted**';
                            response.body = JSON.stringify(body);
                        }
                        return response;
                    }
                }
            });
        }
    } catch (error) {
        console.log(error);
    }

    const updateToken = async () => {
        try {
            if (called) return;

            const auth = getAuth();
            if (!auth) return;

            const refreshToken = getRefreshToken();
            const response = await renewToken(refreshToken, auth.id);
            called = false;
            setAuth({ ...auth, token: response.data.entity.token });
            if (typeof window !== 'undefined') {
                window.location.reload();
            }
        } catch (error) {
            LogRocket.log('logout', error);

            called = false;
            removeAuth();
            removeRT();
            if (typeof window !== 'undefined') {
                window.open('/', '_self');
            }
        }
    };

    const currentUser = getAuth();
    const onSocketConnected = () => {
        if (['/matchings/[id]', '/matchings', '/dashboard',
            '/irs/[id]', '/indexes/[indexId]', '/currencies/[id]', '/bonds/[bondId]'
        ].some((path) => router.pathname === path)) socketEmit('/post/user-group/:join');
        console.log('socket connected');
    };
    const onSocketDisconnected = (e) => {
        console.log('socket disconnected', e);
    };
    const onConnectError = async (err) => {
        console.log(`connect_error due to ${err.message}`);
        if (err.message === 'invalid token') { await updateToken(); }
        if (err.message === 'session_expire') {
            const auth = getAuth();
            try {
                const userDocRef = doc(db, 'presence', auth?.id);
                await updateDocs(userDocRef, {
                    online: false
                });
                await signOut(firebaseAuth);
            } catch (e) { console.log(e); }
            socketEmit('/post/users/sessions/logout', { sessionId: auth?.sessionId });
            logout();
        }
        socket.io.opts.transports = ['websocket'];
    };

    const onLogout = () => {
        window.location.reload();
    };

    useEffect(() => {
        const onAnyEvent = (name, value) => {
            if (value?.error) {
                if (value.error?.error === 'unauthorized') {
                    updateToken();
                }
            }
        };
        const auth = getAuth();
        if (auth?.id) {
            LogRocket.identify(auth.id, {
                email: auth.email, name: auth.name, companyName: auth.companyName, role: auth.role, sessionId: auth.sessionId
            });
        }
        socket.on('connect', onSocketConnected);
        socket.on('disconnect', onSocketDisconnected);
        socket.on('connect_error', onConnectError);
        socket.prependAny(onAnyEvent);
        socket.on('/post/users/sessions/logout', onLogout);
        socketConnect();
        if (isAuthenticated()) {
            socketEmit('/post/users/sessions/:sessionId', { sessionId: auth?.sessionId });
        }

        return () => {
            socket.off('connect', onSocketConnected);
            socket.off('disconnect', onSocketDisconnected);
            socket.off('connect_error', onConnectError);
            socket.off('/post/users/sessions/logout', onLogout);
            socket.offAny(onAnyEvent);
        };
    }, []);

    useEffect(() => {
        if (currentUser?.id) {
            if (complianceRoles.includes(currentUser.role)) return;
            const presenceRef = doc(db, 'presence', currentUser.id);

            setDocs(presenceRef, { online: true });

            const unsubscribe = onSnapshot(presenceRef, (snapshot) => {
                const data = snapshot.data();
                if (data && !data.online) {
                    setTimeout(() => {
                        const checkAuth = getAuth();
                        if (checkAuth) {
                            setDocs(presenceRef, { online: true });
                        }
                    }, 1000);
                }
            });

            const handleBeforeUnload = () => {
                setDocs(presenceRef, { online: false });
                unsubscribe();
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [currentUser]);

    return (
        <>
            <ErrorBoundary onError={(event) => {
                const customData = {
                    userId: currentUser?.id,
                    name: currentUser?.name,
                    email: currentUser?.email,
                    role: currentUser?.role
                };
                event.addMetadata('custom', customData);
            }}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    />
                    <title>
                        {getPageTitle(router.pathname)}
                    </title>
                </Head>
                <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
                    <ToastContainer />
                    <StatusProvider>
                        <WithAuth router={router}>
                            <Header />
                            <AppLoader path={router.pathname}/>
                            <Component {...pageProps} />
                        </WithAuth>
                    </StatusProvider>
                </FlagsmithProvider>
            </ErrorBoundary>
        </>
    );
}

MyApp.getInitialProps = async () => {
    await flagsmith.init({
        environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY
    });
    return { flagsmithState: flagsmith.getState() };
};

export default MyApp;
