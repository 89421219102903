import { getAuth, isAuthenticated } from 'services/auth.service';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
    const unProtectedRoutes = ['/', '/forgot-password'];

    let isProtected = !unProtectedRoutes.includes(router.pathname);
    isProtected = !unProtectedRoutes.some(prefix => router.pathname.startsWith(prefix));

    const auth = getAuth();
    if (isBrowser() && !isAuthenticated(auth) && isProtected) {
        router.replace('/');
        return null;
    }
    return children;
};

export default WithAuth;
