import { io } from 'socket.io-client';
import { getAuth } from './auth.service';

export const socket = io(process.env.NEXT_PUBLIC_WEB_SOCKET_BASE_URL, {
    autoConnect: false,
    transports: ['websocket', 'webtransport', 'polling']
});
export const socketEmit = (event, data = {}) => socket.emit(event, { ...data, token: getAuth()?.token });
export const socketConnect = () => {
    if (!getAuth()?.token) return;
    socket.auth = { token: getAuth()?.token };
    if (!socket.connected) socket.connect();
};
export const saveToLogs = (data = {}) => socket.emit('/logs', { ...data, token: getAuth()?.token });
