import axios from 'axios';
import * as HttpService from './http.service';
import { GET_UPLOAD_PRE_SIGNED_URL } from './url.service';

const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL;
const cloudFrontUrl = process.env.NEXT_PUBLIC_CLOUDFRONT_URL;

export const uploadToS3Bucket = (fileKey, file) => new Promise((resolve, reject) => {
    HttpService.postWithAuth(GET_UPLOAD_PRE_SIGNED_URL, { fileKey })
        .then(async (mediaKey) => {
            const formData = new FormData();
            for (const key in mediaKey.data.entity.fields) {
                if (Object.hasOwnProperty.call(mediaKey.data.entity.fields, key)) {
                    const element = mediaKey.data.entity.fields[key];
                    formData.append(key, element);
                }
            }

            formData.append('acl', process.env.NEXT_PUBLIC_BUCKET_ACL);
            formData.append('Content-Type', file.type);
            // formData.append('Cache-Control', 'max-age=31536000');
            formData.append('file', file);

            const response = await axios({
                method: 'post',
                url: mediaKey.data.entity.url,
                data: formData,
                headers: { 'Content-Type': file.type }
            });
            return getMediaUrl(fileKey);
        }).then((res) => {
            resolve(res);
        });
});

export const getMediaUrl = (fileKey) => new Promise((resolve, reject) => {
    resolve(fileKey);
});

export const getFileUrl = (fileKey) => (!fileKey ? null : bucketUrl + fileKey);

export const fetchS3FileAndCreateBlob = async (s3Url) => {
    try {
        const response = await fetch(s3Url);
        if (!response.ok) {
            throw new Error('Failed to fetch the file from S3');
        }
        const fileData = await response.blob(); // Create a Blob from the response
        const file = new File([fileData], 'image.jpg', { type: 'image/jpeg' });
        return file;
    } catch (error) {
        console.error('Error fetching S3 file:', error);
        return null;
    }
};

export const getMediaFromCloudFrontUrl = (fileKey) => {
    if (fileKey?.includes(cloudFrontUrl)) return fileKey;
    if (cloudFrontUrl && fileKey?.includes('users/profile_pictures')) {
        const fileName = fileKey?.split('users/profile_pictures/')[1];
        if (fileName) {
            return `${cloudFrontUrl}${fileName}`;
        }
    }
    if (fileKey?.includes(bucketUrl)) return fileKey;
    return fileKey ? `${bucketUrl}${fileKey}` : '/images/user.png';
};
